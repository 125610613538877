import {
    Dispatch,
    FC,
    SetStateAction,
    Suspense,
    memo,
    useState,
} from 'react';

import {
    Button,
} from 'antd';

import LeftOutlined from '@ant-design/icons/LeftOutlined';
import { IChannel } from 'entities/channels';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { DynamicHeader } from 'shared/ui/dynamic-header/DynamicHeader';
import { Flex } from 'shared/ui/flex/Flex';
import { FullscreenContainer } from 'shared/ui/fullscreen-container/FullscreenContainer';
import { PageLoader } from 'shared/ui/page-loader/PageLoader';

import { CustomUploadFile } from '../../../model/types/create-message.types';
import { CreateMessageContentAsync } from '../../content/create-message/CreateMessageContent.async';
import { SaveMessage } from '../../save-message/SaveMessage';

import cls from './CreateTableMessage.module.scss';

interface ICreateTableMessageProps {
    toggleModal: () => void;
    handleCancelModal: () => void;
    localFileMediaList: CustomUploadFile[];
    setLocalFileMediaList: Dispatch<SetStateAction<CustomUploadFile[]>>;
    localFileAudioList: CustomUploadFile[];
    setLocalFileAudioList: Dispatch<SetStateAction<CustomUploadFile[]>>;
    handleReloadChannels: () => void;
    channel: IChannel;
    setIsUpdateChannels: Dispatch<SetStateAction<boolean>>;
}

export const CreateTableMessage: FC<ICreateTableMessageProps> = memo((props) => {
    const {
        channel,
        handleCancelModal,
        handleReloadChannels,
        localFileAudioList,
        localFileMediaList,
        setIsUpdateChannels,
        setLocalFileAudioList,
        setLocalFileMediaList,
        toggleModal,
    } = props;

    const [isHiddenScroll, setIsHiddenScroll] = useState(false);

    return (
        <FullscreenContainer>
            <DynamicHeader
                className={cls.header}
                leftBlock={(
                    <LeftOutlined
                        className={cls.backIcon}
                        onClick={handleCancelModal}
                    />
                )}
                title="Создание сообщения"
            />
            <Flex
                className={classNames(cls.contentWrapper, { [cls.hiddenScroll]: isHiddenScroll })}
                gaps="16"
                max
                direction="column"
                align="start"
            >
                <Suspense fallback={<PageLoader isLoading withoutPortal classNameWithoutPortal={cls.loaderOnLoad} />}>
                    <CreateMessageContentAsync
                        localFileAudioList={localFileAudioList}
                        localFileMediaList={localFileMediaList}
                        setLocalFileAudioList={setLocalFileAudioList}
                        setLocalFileMediaList={setLocalFileMediaList}
                        setIsHiddenScroll={setIsHiddenScroll}
                    />

                    <Flex className={cls.btnsModalControl} max gaps="8">
                        <Button size="large" block key="back" onClick={toggleModal}>
                            Отменить
                        </Button>

                        <SaveMessage
                            key="save"
                            cancelModal={handleCancelModal}
                            handleReloadChannels={handleReloadChannels}
                            setIsUpdateChannels={setIsUpdateChannels}
                            channel={channel}
                        />
                    </Flex>
                </Suspense>
            </Flex>
        </FullscreenContainer>
    );
});
