import dayjs, { Dayjs } from 'dayjs';

import { isDateAndTimeBeforeNow } from 'shared/lib/utils/date/date';

import { DateValidVariant } from '../model/types/edit-message.types';

export const validateDate = (
    date: Dayjs,
    minutes: Dayjs,
    timezone: string,
    onChangeError: (error: DateValidVariant) => void,
): DateValidVariant => {
    if (isDateAndTimeBeforeNow(date, minutes, timezone)) {
        const now = dayjs().tz(timezone);
        const combinedDateTime = date.hour(minutes.hour()).minute(minutes.minute());
        if (combinedDateTime.isBefore(now, 'day')) {
            onChangeError('error-date');
            return 'error-date';
        }

        if (combinedDateTime.isBefore(now)) {
            onChangeError('error-time');
            return 'error-time';
        }
    }

    onChangeError('');
    return '';
};

export const disabledDate = (current: Dayjs, timezone: string) => {
    const format = 'MM/DD/YYYY';
    const currentDate = dayjs(current).format(format);
    const nowDate = dayjs().tz(timezone).format(format);

    return dayjs(currentDate).startOf('day') < dayjs(nowDate).startOf('day');
};

export const disabledTime = (date: Dayjs | null, timezone: string, selectedDate: Dayjs | null) => {
    const now = dayjs().tz(timezone);
    const currentHour = now.hour();
    const currentMinute = now.minute();
    const currentSecond = now.second();

    if (!selectedDate || !selectedDate.isSame(now, 'day')) {
        return {
            disabledHours: () => [],
            disabledMinutes: () => [],
            disabledSeconds: () => [],
        };
    }

    return {
        disabledHours: () => Array.from({ length: currentHour }, (_, i) => i),
        disabledMinutes: () => (currentHour === date?.hour()
            ? Array.from({ length: currentMinute + 1 }, (_, i) => i) : []),
        disabledSeconds: () => ((currentHour === dayjs().hour() && currentMinute === dayjs().minute())
            ? Array.from({ length: currentSecond }, (_, i) => i) : []),
    };
};
