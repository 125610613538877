import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import { IStateSchema } from 'app/providers/store';

import {
    IChannel,
    IChannelsSchema,
} from '../types/channels.types';

function sortByErrorsCountAndCreatedAt(channelOne: IChannel, channelTwo: IChannel) {
    if (channelTwo.errorsCount > channelOne.errorsCount) {
        return 1;
    }
    if (channelTwo.errorsCount < channelOne.errorsCount) {
        return -1;
    }

    return channelTwo.createdAt.localeCompare(channelOne.createdAt);
}

const channelsAdapter = createEntityAdapter<IChannel>({
    selectId: (message) => message.id,
    sortComparer: sortByErrorsCountAndCreatedAt,
});

export const getChannelsFromSlice = channelsAdapter.getSelectors<IStateSchema>(
    (state) => state?.channels || channelsAdapter.getInitialState(),
);

export const channelSlice = createSlice({
    initialState: channelsAdapter.getInitialState<IChannelsSchema>({
        data: [],
        entities: {},
        ids: [],
        nextUrlChannel: null,
        openCreateMessageModal: false,
        previousUrlChannel: null,
        searchName: '',
        selectChannelName: '',
        shouldUpdateChannelId: '',
    }),
    name: 'channels',
    reducers: {
        addChannel: channelsAdapter.addOne,
        addChannels: channelsAdapter.addMany,
        removeChannel: channelsAdapter.removeOne,
        setChannelsList: (state, { payload: channels }: PayloadAction<IChannel[]>) => {
            channelsAdapter.setAll(state, channels);
        },
        setOpenCreateMessageModal(state, { payload: value }: PayloadAction<IChannelsSchema['openCreateMessageModal']>) {
            state.openCreateMessageModal = value;
        },
        setSearchName(state, { payload: value }: PayloadAction<IChannelsSchema['searchName']>) {
            state.searchName = value;
        },
        setSelectChannelName: (state, { payload: name }: PayloadAction<IChannelsSchema['selectChannelName']>) => {
            state.selectChannelName = name;
        },
        setShouldUpdateChannelId: (state, { payload: id }: PayloadAction<IChannelsSchema['shouldUpdateChannelId']>) => {
            state.shouldUpdateChannelId = id;
        },
        updateChannel: channelsAdapter.updateOne,
        updateNextUrlChannel(state, { payload: nextUrl }: PayloadAction<IChannelsSchema['nextUrlChannel']>) {
            state.nextUrlChannel = nextUrl;
        },
        updatePreviousUrlChannel(state, { payload: previousUrl }: PayloadAction<IChannelsSchema['previousUrlChannel']>) {
            state.previousUrlChannel = previousUrl;
        },

    },
});

export const { reducer: channelReducer } = channelSlice;
export const { actions: channelActions } = channelSlice;
