import {
    Dispatch,
    FC,
    SetStateAction,
    memo,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import {
    IChannel,
    channelActions,
    getOpenCreateMessageModal,
} from 'entities/channels';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';

import { createMessageActions } from '../model/slice/create-message.slice';
import { CustomUploadFile } from '../model/types/create-message.types';

import { CreateMessageModal } from './modal/create-message/CreateMessageModal';
import { CreateTableMessage } from './modal/create-table-message/CreateTableMessage';
import './CreateMessage.scss';

interface ICreateMessageProps {
    handleReloadChannels?: () => void;
    setIsUpdateChannels?: Dispatch<SetStateAction<boolean>>;
    channel?: IChannel;
}

export const CreateMessage: FC<ICreateMessageProps> = memo((props) => {
    const {
        channel, handleReloadChannels = () => {}, setIsUpdateChannels = () => {},
    } = props;
    const dispatch = useAppDispatch();

    const open = useSelector(getOpenCreateMessageModal);
    const [localFileMediaList, setLocalFileMediaList] = useState<CustomUploadFile[]>([]);
    const [localFileAudioList, setLocalFileAudioList] = useState<CustomUploadFile[]>([]);

    const { isTablet } = useScreenType();

    const toggleModal = useCallback(() => {
        dispatch(channelActions.setOpenCreateMessageModal(!open));
    }, [open]);

    const handleCancelModal = useCallback(() => {
        toggleModal();
        setLocalFileMediaList([]);
        setLocalFileAudioList([]);
    }, [toggleModal]);

    useEffect(() => {
        if (channel && open) {
            dispatch(createMessageActions.clearData());
            dispatch(createMessageActions.setTimezone(channel?.timezone ?? ''));
            dispatch(createMessageActions.setVariables(channel?.variables || []));
        }
    }, [channel, open]);

    if (!channel) {
        return null;
    }

    if (isTablet && open) {
        return (
            <CreateTableMessage
                toggleModal={toggleModal}
                handleCancelModal={handleCancelModal}
                localFileMediaList={localFileMediaList}
                setLocalFileMediaList={setLocalFileMediaList}
                localFileAudioList={localFileAudioList}
                setLocalFileAudioList={setLocalFileAudioList}
                handleReloadChannels={handleReloadChannels}
                setIsUpdateChannels={setIsUpdateChannels}
                channel={channel}
            />
        );
    }

    return (
        <CreateMessageModal
            open={open}
            toggleModal={toggleModal}
            handleReloadChannels={handleReloadChannels}
            setIsUpdateChannels={setIsUpdateChannels}
            localFileMediaList={localFileMediaList}
            setLocalFileMediaList={setLocalFileMediaList}
            localFileAudioList={localFileAudioList}
            setLocalFileAudioList={setLocalFileAudioList}
            channel={channel}
            handleCancelModal={handleCancelModal}
        />
    );
});
